.btn {
    padding: 10px;
    border-radius: 18px;
    position: relative;
    text-transform: uppercase;
    font-family: "Inter", sans-serif;
}

.btn-purple {
    color: #fff;
}
